<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="headers"
      :items="cities"
      :search="search"
    >
      <template #top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Cidades das Unidades de Atendimento</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar"
            single-line
            hide-details
          />

          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
      <template #item.createdAt="{ item }">
        <p>{{ item.createdAt | parseDate }}</p>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
    >
      <form-city
        v-if="dialog"
        :populate-with="cityEdit"
        @city-added="addCity"
        @city-edited="editCity"
        @close-dialog="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import UnitsCityService from '../../../services/units-city.service';
// import DeleteConfirmation from '../../../components/shared/DeleteConfirmation';

export default {
  name: 'UnitCityList',
  components: {
    'form-city': () => import('./Form'),
    // DeleteConfirmation,
  },

  data: () => ({
    headers: [
      {
        text: 'Cidade',
        sortable: false,
        value: 'city',
      },
      { text: 'Data de Criação', value: 'createdAt', sortable: false },
      { text: 'Ações', value: 'actions', sortable: false },
    ],

    search: '',
    cities: [],
    dialog: false,
    cityEdit: {},
    // dialogDeleteConfirmation: false,
  }),

  async created() {
    try {
      const result = await UnitsCityService.findAll();
      this.cities = result;
    } catch (e) {
      this.$handleHttpError(e);
    }
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.cityEdit = {};
    },

    editItem(item) {
      this.cityEdit = item;
      this.dialog = true;
    },

    // prepareToDelete(item) {
    //   this.dialogDeleteConfirmation = true;
    //   this.deleteItemID = item.id;
    // },

    // async deleteItem() {
    //   try {
    //     await UnitsCityService.deleteOne(this.deleteItemID);
    //     const index = this.cities.findIndex((listCitys) => listCitys.id === this.deleteItemID);
    //     this.cities.splice(index, 1);
    //     this.dialogDeleteConfirmation = false;
    //     this.$toast.success('Cidade excluída com sucesso');
    //   } catch (e) {
    //     this.$handleHttpError(e);
    //   }
    // },

    addCity(cities) {
      this.cities.push(cities);
    },

    editCity(cities) {
      const index = this.cities.findIndex((listCitys) => listCitys.id === cities.id);
      this.cities[index] = cities;
    },
  },
};
</script>

<style scoped>

</style>
