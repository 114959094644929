import api from '@/services/api';

const url = 'city';

const create = (city) => api.post(`${url}/create-unit-city`, city);

const findAll = () => api.get(`${url}/read-unit-city`);

const update = (city) => api.put(`${url}/update-unit-city`, city);

const deleteOne = (id) => api.delete(`${url}/delete-unit-city/${id}`);

export default {
  create, findAll, update, deleteOne,
};
